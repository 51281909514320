import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import AutoLink from "./AutoLink"
import Image from "./Image"
import theme from "../styles/theme"

const TeamMemberGrid = ({ title, className }) => {
  const {
    data: { teamMembers },
  } = useStaticQuery(graphql`
    {
      data: allSanityTeamMember(sort: { fields: lastName }) {
        teamMembers: nodes {
          firstName
          lastName
          slug {
            current
          }
          position
          headshot {
            ...Image
          }
        }
      }
    }
  `)

  return (
    <div className={className}>
      <h2 css={theme.h2}>{title}</h2>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, 125px)",
          gap: 30,
          justifyContent: "start",
          marginTop: 64,
          marginRight: -50,
          a: { textDecoration: "none" },
          [theme.tablet]: {
            marginTop: 60,
            marginRight: 0,
          },
          [theme.tablet]: {
            gridTemplateColumns: "repeat(auto-fill, 115px)",
          },
          [theme.mobile]: {
            marginTop: 35,
            justifyContent: "center",
            marginLeft: -10,
            marginRight: -10,
            gridTemplateColumns: "repeat(auto-fill, 130px)",
          },
        }}
      >
        {teamMembers.map(
          ({ firstName, lastName, slug, position, headshot }) => (
            <AutoLink key={slug.current} to={slug.current}>
              <Image
                {...headshot}
                alt=""
                width={175}
                css={{ display: "block", maxWidth: "100%" }}
              />
              <div
                css={{
                  ...theme.h4,
                  fontSize: 18,
                  fontWeight: 700,
                  margin: 0,
                }}
              >
                {firstName} {lastName}
              </div>
              <div
                css={{
                  textTransform: "uppercase",
                  color: theme.red,
                  fontSize: "0.625rem",
                  fontWeight: 500,
                  letterSpacing: "0.1071428571em",
                }}
              >
                {position}
              </div>
            </AutoLink>
          )
        )}
      </div>
    </div>
  )
}

export default TeamMemberGrid

TeamMemberGrid.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}
