import React from "react"
import PropTypes from "prop-types"
import { useFormField } from "./useFormField"
import ErrorMessage from "components/form/ErrorMessage"
import theme from "styles/theme"
import { deserializeValue, serializeValue } from "components/form/serializers"

const ShirtSizesInput = ({ name, label, required, className, ...rest }) => {
  const { errorMessage, value = "", onChange, onBlur } = useFormField({
    name,
    validator,
    required: false,
  })

  const state = deserializeValue(value)

  const updateState = event => {
    const newState = { ...state }
    newState[event.target.name] = parseInt(event.target.value)

    onChange({ target: { name, value: serializeValue(newState) } })
  }

  return (
    <div
      css={{
        position: "relative",
        marginBottom: 16,
      }}
      className={className}
    >
      {label && <h4 css={{ ...theme.h4, marginBottom: 16 }}>{label}</h4>}

      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)",
          columnGap: 5,
          marginRight: "35%",
          "@media (max-width: 850px)": {
            marginRight: "20%",
          },
          [theme.mobile]: {
            gridTemplateColumns: "repeat(2, 1fr), ",
            gap: 10,
          },
        }}
      >
        {Object.entries(state).map(([sizeName, value]) => (
          <div key={sizeName}>
            <label
              htmlFor={`field-${sizeName}`}
              css={{
                position: "relative",
                fontSize: 13,
                textTransform: "uppercase",
                fontWeight: 500,
                display: "block",
                color: theme.teal,
                marginBottom: "0.25em",
                cursor: "pointer",
              }}
            >
              {sizeName.toUpperCase()}
            </label>
            <input
              type="number"
              min="0"
              max="10"
              id={`field-${sizeName}`}
              name={sizeName}
              label={sizeName.toUpperCase()}
              value={value}
              onChange={updateState}
              onBlur={() => {
                onBlur({ target: { name, value: serializeValue(state) } })
              }}
              css={{
                gridColumn: "span 1",
                display: "block",
                width: "100%",
                fontFamily: "inherit",
                padding: "0.5em 0.7em",
                border: `1px solid ${errorMessage ? theme.red : theme.n60}`,
                fontSize: 14,
                color: theme.teal,
                ":focus": {
                  outline: 0,
                  borderColor: theme.n20,
                },
                [theme.mobile]: { gridColumn: "1" },
              }}
            />
          </div>
        ))}

        <h4
          css={{
            gridColumn: "span 6",
            [theme.mobile]: { gridColumn: "1" },
          }}
        >
          Total Quantity: {shirtCount(state)} of 10
        </h4>
      </div>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  )
}

export default ShirtSizesInput

ShirtSizesInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  transformer: PropTypes.func,
  validator: PropTypes.func,
  className: PropTypes.string,
}

const validator = value => {
  const count = shirtCount(deserializeValue(value))

  return count > 10
    ? [false, "Maximum of 10 shirts per order"]
    : count < 1
    ? [false, "Minimum of 1 shirt per order"]
    : [true]
}

const shirtCount = state => Object.values(state).reduce((sum, n) => sum + n, 0)
