import React from "react"
import theme from "styles/theme"

const ErrorMessage = props => (
  <div
    css={{
      display: "inline-block",
      background: theme.red,
      color: theme.n100,
      fontSize: 13,
      fontWeight: 500,
      padding: "0.5em 1em",
      position: "relative",
      ":before": {
        content: "''",
        position: "absolute",
        top: -6,
        left: "1em",
        height: 0,
        width: 0,
        borderBottom: `6px solid ${theme.red}`,
        borderLeft: "6px solid transparent",
        borderRight: "6px solid transparent",
      },
    }}
    {...props}
  />
)

export default ErrorMessage
