import React from "react"
import PropTypes from "prop-types"
import validateEmail from "./validators/email"
import TextInput from "components/form/TextInput"

const downcaseValue = value => value.toLowerCase()

const EmailInput = props => (
  <TextInput
    type="email"
    autoComplete="email"
    validator={validateEmail}
    transformer={downcaseValue}
    {...props}
  />
)

EmailInput.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
}

export default EmailInput
