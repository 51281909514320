// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-newclient-jsx": () => import("./../src/pages/newclient.jsx" /* webpackChunkName: "component---src-pages-newclient-jsx" */),
  "component---src-templates-directory-page-jsx": () => import("./../src/templates/DirectoryPage.jsx" /* webpackChunkName: "component---src-templates-directory-page-jsx" */),
  "component---src-templates-page-jsx": () => import("./../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-team-member-jsx": () => import("./../src/templates/TeamMember.jsx" /* webpackChunkName: "component---src-templates-team-member-jsx" */)
}

