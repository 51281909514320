import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Metadata = ({ title, description }) => {
  const {
    metadata: { defaults },
  } = useStaticQuery(graphql`
    {
      metadata: sanityGeneralSettings {
        defaults: metadata {
          title
          description
        }
      }
    }
  `)

  return (
    <Helmet>
      <title>{title || defaults.title}</title>
      <meta name="description" content={description || defaults.description} />
      <meta
        property="og:description"
        content={description || defaults.description}
      />
      <meta property="og:title" content={title || defaults.title} />
      <meta property="og:type" content="website" />
    </Helmet>
  )
}

export default Metadata

Metadata.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export const fragmentQuery = graphql`
  fragment PageMetadata on SanityMetadata {
    title
    description
  }
`
