import React from "react"
import PropTypes from "prop-types"
import theredoc from "theredoc"
import btoa from "btoa"
import { useStaticQuery, graphql } from "gatsby"

const VCardLink = ({
  firstName,
  lastName,
  position,
  emailAddress,
  phoneNumber,
  officeAddress: { address },
  linkedin,
  twitter,
  className,
  children,
}) => {
  const { settings } = useStaticQuery(graphql`
    {
      settings: sanityTeamSettings {
        organizationName
        websiteAddress
      }
    }
  `)

  const vcardAddress = [
    [address.street, address.street2].filter(Boolean).join(", "),
    address.city,
    address.state,
    address.zipcode,
  ].join(";")

  const vcard = theredoc`
    BEGIN:VCARD
    VERSION:3.0
    N:${lastName};${firstName};;;
    TITLE:${position}
    ORG:${settings.organizationName}
    EMAIL;type=WORK;type=pref:${emailAddress}
    TEL;type=CELL;type=VOICE;type=pref:${phoneNumber}
    ADR;type=WORK;type=pref:;;${vcardAddress};
    URL;type=WORK;type=pref:${settings.websiteAddress}
    ${linkedin && `URL;type=pref:${linkedin}`}
    ${twitter && `URL;type=pref:${twitter}`}
    END:VCARD
  `
  const vcardData = btoa(
    vcard
      .split("\n")
      .filter(Boolean)
      .join("\n")
  )

  return (
    <a
      href={`data:text/vcard;base64,${vcardData}`}
      download={`${firstName} ${lastName}.vcf`}
      className={className}
    >
      {children}
    </a>
  )
}

export default VCardLink

VCardLink.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
  officeAddress: PropTypes.shape({
    address: PropTypes.shape({
      street: PropTypes.string.isRequired,
      street2: PropTypes.string,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}
