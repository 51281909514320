import React, { useState, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import theme from "../styles/theme"
import AutoLink from "components/AutoLink"
import useOnClickOutside from "hooks/useOnClickOutside"

const Dropdown = ({ label, links, className }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef()
  const closeDropdown = useCallback(() => {
    setOpen(false)
  }, [])
  useOnClickOutside(ref, closeDropdown, open)

  return (
    <div
      ref={ref}
      css={{
        position: "relative",
        display: "inline-block",
        [theme.mobile]: {
          display: "block",
        },
      }}
      className={className}
    >
      <a
        href="#"
        onClick={e => {
          e.preventDefault()
          setOpen(prev => !prev)
        }}
        css={{
          ...theme.smallcaps(14, 400),
          color: theme.teal,
          [theme.tablet]: theme.smallcaps(12),
          [theme.mobile]: {
            display: "block",
            background: theme.teal,
            color: theme.n100,
            padding: "10px 48px 10px 16px",
            marginBottom: 7,
            ":hover": {
              opacity: 1,
            },
          },
        }}
      >
        <DropdownArrow
          width={12}
          css={{
            marginRight: "0.25em",
            transition: "transform 250ms",
            transform: open && "rotateX(180deg)",
            [theme.mobile]: {
              transform: `translate(-5px, 2px) rotateX(${open ? 180 : 0}deg)`,
            },
          }}
        />
        {label}
      </a>

      <div
        css={{
          position: "absolute",
          top: "calc(100% + 16px)",
          background: theme.n100,
          padding: "8px 24px",
          boxShadow:
            "0px 4px 8px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)",
          transition: "transform 250ms, opacity 250ms",
          transformOrigin: "top center",
          transform: open ? "rotateX(0)" : "rotateX(90deg)",
          opacity: open ? 1 : 0,
          zIndex: 5,
          textAlign: "left",
          width: 256,
          maxWidth: "100vw",
          [theme.mobile]: {
            top: "100%",
            right: 0,
          },
        }}
      >
        {links.map(({ _key, title, url }) => (
          <AutoLink
            key={_key}
            to={url}
            onClick={closeDropdown}
            css={{
              ...theme.smallcaps(12, 500),
              display: "block",
              borderBottom: `1px solid ${theme.n80}`,
              padding: "10px 0",
              color: theme.teal,
              ":last-child": {
                borderBottom: 0,
              },
            }}
          >
            {title}
          </AutoLink>
        ))}
      </div>
    </div>
  )
}

export default Dropdown

Dropdown.propTypes = {
  label: PropTypes.node.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  className: PropTypes.string,
}

const DropdownArrow = props => (
  <svg viewBox="0 0 12 12" {...props}>
    <path fill={theme.red} d="M11 3H1l5 6 5-6z" />
  </svg>
)
