import React from "react"
import PropTypes from "prop-types"
import BlockContent from "./BlockContent"
import theme from "../styles/theme"
import FancyList from "components/FancyList"

const Subcontent = ({ content }) => (
  <BlockContent
    blockOverrides={{
      h4: Subtitle,
      blockquote: Blockquote,
    }}
    serializers={{
      list: SubcontentList,
    }}
    css={{
      borderLeft: `10px solid ${theme.red}`,
      paddingLeft: 42,
      margin: "30px 0 42px 0",
      color: theme.teal,
    }}
  >
    {content}
  </BlockContent>
)

export default Subcontent

Subcontent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const Subtitle = props => (
  <h4 css={{ ...theme.smallcaps(), marginTop: 28 }} {...props} />
)

const Blockquote = props => (
  <blockquote
    css={{ marginLeft: 0, fontSize: 14, fontStyle: "italic" }}
    {...props}
  />
)

const SubcontentList = props => (
  <FancyList
    css={{
      fontFamily: theme.displayFontFamily,
      fontSize: 14,
      li: {
        marginBottom: 18,
        ":before": {
          top: 5,
          width: 8,
          height: 8,
          left: -8 - 18,
        },

        // Nested list styles
        ul: {
          marginTop: 18,
        },
        li: {
          marginBottom: 8,
        },
      },
    }}
  >
    {props.children}
  </FancyList>
)
