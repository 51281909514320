import React, { useContext } from "react"
import FormPage from "components/form/FormPage"
import TextInput from "components/form/TextInput"
import EmailInput from "components/form/EmailInput"
import FileInput from "components/form/FileInput"
import ShirtSizesInput from "components/form/ShirtSizesInput"
import CheckboxInput from "components/form/CheckboxInput"
import { FormContext } from "components/form/ValidatedForm"
import theme from "styles/theme"

const ShirtSignup = () => {
  const formState = useContext(FormContext)

  return (
    <FormPage
      title="Company T-Shirts"
      intro="Complete this form if you would like to receive a complimentary new client welcome gift of 10 customized t-shirts. One of our attorneys has a brother with a t-shirt company, and this is our way of supporting that small business."
    >
      <div>
        <ShirtSizesInput label="Sizes" name="shirt_sizes" />

        <FileInput
          name="artwork"
          label="Your Company Logo (Ai, PNG, EPS - max size 8MB)"
          accept=".ai, .png, .eps"
          required
        />

        <h4 css={theme.h4}>Contact</h4>

        <div
          css={{
            display: "grid",
            gridTemplateColumns: "repeat(6, 1fr)",
            gap: "20px 40px",
            marginTop: 20,
            [theme.tablet]: {
              marginRight: 0,
            },
            [theme.mobile]: {
              gridTemplateColumns: "1fr",
              gap: 20,
              marginLeft: -10,
              marginRight: -10,
            },
          }}
        >
          <TextInput
            name="contact_name_shipping"
            label="Full Name"
            autoComplete="name"
            required
            css={{
              gridColumn: "span 6",
              [theme.mobile]: { gridColumn: "1" },
            }}
          />

          <CheckboxInput
            name="shipToBilling"
            label="Ship to billing address"
            css={{
              gridColumn: "span 6",
              [theme.mobile]: { gridColumn: "1" },
            }}
          />

          <div
            css={{
              display: formState?.fields?.shipToBilling?.value
                ? "none"
                : "contents",
            }}
          >
            <TextInput
              name="address_1_shipping"
              label="Address"
              autoComplete="address-line1"
              required={!formState?.fields?.shipToBilling?.value}
              css={{
                gridColumn: "span 6",
                [theme.mobile]: { gridColumn: "1" },
              }}
            />
            <TextInput
              name="address_2_shipping"
              label="Address (Line 2)"
              autoComplete="address-line2"
              css={{
                gridColumn: "span 6",
                [theme.mobile]: { gridColumn: "1" },
              }}
            />
            <TextInput
              name="city_shipping"
              label="City"
              autoComplete="address-level2"
              required={!formState?.fields?.shipToBilling?.value}
              css={{
                gridColumn: "span 2",
                [theme.mobile]: { gridColumn: "1" },
              }}
            />
            <TextInput
              name="state_shipping"
              label="State"
              autoComplete="address-level1"
              required={!formState?.fields?.shipToBilling?.value}
              css={{
                gridColumn: "span 2",
                [theme.mobile]: { gridColumn: "1" },
              }}
            />
            <TextInput
              name="zip_code_shipping"
              label="ZIP Code"
              autoComplete="postal-code"
              validator={zipCodeValidator}
              required={!formState?.fields?.shipToBilling?.value}
              css={{
                gridColumn: "span 2",
                [theme.mobile]: { gridColumn: "1" },
              }}
            />
          </div>
          <EmailInput
            name="email_shipping"
            label="Email Address"
            autoComplete="email"
            required
            css={{
              gridColumn: "span 3",
              [theme.mobile]: { gridColumn: "1" },
            }}
          />
          <TextInput
            name="phone_shipping"
            label="Phone Number"
            autoComplete="tel-national"
            required
            css={{
              gridColumn: "span 3",
              [theme.mobile]: { gridColumn: "1" },
            }}
          />
        </div>
      </div>
    </FormPage>
  )
}

export default ShirtSignup

const zipCodeValidator = value =>
  value === "" ||
  typeof value === "undefined" ||
  /^[0-9]{5}$/.test(String(value))
    ? [true]
    : [false, "Please enter a valid zip code"]
