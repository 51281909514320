import normalize from "emotion-normalize"
import wolpePegasus from "../fonts/wolpePegasus"

const colors = {
  // Neutral colors / grays
  // naming scheme: n{HSB brightness, rounded to tens}
  n100: "#FFFFFF",
  n60: "#A0A5A7",
  n80: "#DFE1E1",
  n20: "#142637",

  // Brand Colors
  teal: "#1A5371",
  red: "#E31E2D",
}
colors.white = colors.n100
colors.lightGrey = colors.n80
colors.mediumGrey = colors.n60
colors.coolBlack = colors.n20

const fontFamily =
  "Gotham Narrow SSm A, Gotham Narrow SSm B, Gotham Narrow, Helvetica Neue, Helvetica, Arial, sans-serif"
const displayFontFamily = "Wolpe Pegasus, Georgia, serif"

const breakpoints = {
  desktop: "@media (min-width: 1025px)",
  tablet: "@media (max-width: 1024px)",
  mobile: "@media (max-width: 700px)",
}

export default {
  ...colors,
  ...breakpoints,

  fontFamily,
  displayFontFamily,
  contentLeftPadding: "var(--contentLeftPadding)",
  contentRightPadding: "var(--contentRightPadding)",

  // Headings and Text Styles
  h1: {
    fontSize: "var(--h1)",
    fontFamily: displayFontFamily,
    color: colors.teal,
    lineHeight: 1.15,
  },

  h2: {
    fontFamily: displayFontFamily,
    fontSize: 30,
    color: colors.teal,

    [breakpoints.tablet]: { fontSize: 24 },
    [breakpoints.mobile]: { fontSize: 20 },
  },

  h4: {
    fontFamily: displayFontFamily,
    fontSize: 22,
    color: colors.teal,
  },

  smallcaps: (fontSize = 14, fontWeight = 500) => ({
    fontSize,
    fontWeight,
    textTransform: "uppercase",
    letterSpacing: 1.5 / fontSize + "em",
  }),
}

export const globalStyles = [
  normalize,
  wolpePegasus,
  {
    [["*", "*:after", "*:before"]]: {
      boxSizing: "border-box",
    },

    "html, body": {
      fontFamily: fontFamily,
      fontStyle: "normal",
      fontSize: 16,
      lineHeight: 1.375,
      color: colors.n20,
      background: colors.teal,

      // Improve browser font rendering
      textRendering: "optimizeLegibility",
      fontFeatureSettings: "kern 1",
      fontKerning: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",

      "--h1": "40px",
      "--contentLeftPadding": "100px",
      "--contentRightPadding": "180px",

      [breakpoints.tablet]: {
        fontSize: 15,
        "--h1": "30px",
        "--contentLeftPadding": "70px",
        "--contentRightPadding": "80px",
      },

      [breakpoints.mobile]: {
        fontSize: 14,
        "--h1": "26px",
        "--contentLeftPadding": "40px",
        "--contentRightPadding": "30px",
      },
    },

    a: {
      textDecoration: "none",
      ":hover": {
        opacity: 0.8,
      },
    },

    p: {
      marginBottom: "1em",
    },

    img: {
      maxWidth: "100%",
    },
  },
]
