import React from "react"
import PropTypes from "prop-types"
import FormPage from "components/form/FormPage"
import TextInput from "components/form/TextInput"
import EmailInput from "components/form/EmailInput"
import CheckboxInput from "components/form/CheckboxInput"
import Button from "components/Button"
import theme from "styles/theme"

const ClientInformation = ({ logoAgreementCopy }) => (
  <FormPage title="Client Information">
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "repeat(6, 1fr)",
        gap: "20px 40px",
        // marginRight: -75,
        [theme.tablet]: {
          marginRight: 0,
        },
        [theme.mobile]: {
          gridTemplateColumns: "1fr",
          gap: 20,
          marginLeft: -10,
          marginRight: -10,
        },
      }}
    >
      <TextInput
        name="client_name"
        label="Client or Company Name"
        autoComplete="organization"
        required
        css={{ gridColumn: "span 6", [theme.mobile]: { gridColumn: "1" } }}
      />

      <TextInput
        name="contact_name"
        label="Contact Name"
        autoComplete="name"
        required
        css={{ gridColumn: "span 3", [theme.mobile]: { gridColumn: "1" } }}
      />

      <TextInput
        name="contact_title"
        label="Title"
        autoComplete="organization-title"
        required
        css={{ gridColumn: "span 3", [theme.mobile]: { gridColumn: "1" } }}
      />

      <TextInput
        name="address_1"
        label="Address"
        autoComplete="address-line1"
        required
        css={{ gridColumn: "span 6", [theme.mobile]: { gridColumn: "1" } }}
      />

      <TextInput
        name="address_2"
        label="Address (Line 2)"
        autoComplete="address-line2"
        css={{ gridColumn: "span 6", [theme.mobile]: { gridColumn: "1" } }}
      />

      <TextInput
        name="city"
        label="City"
        autoComplete="address-level2"
        required
        css={{ gridColumn: "span 2", [theme.mobile]: { gridColumn: "1" } }}
      />

      <TextInput
        name="state"
        label="State"
        autoComplete="address-level1"
        required
        css={{ gridColumn: "span 2", [theme.mobile]: { gridColumn: "1" } }}
      />

      <TextInput
        name="zip_code"
        label="ZIP Code"
        autoComplete="postal-code"
        validator={value =>
          value === "" || /^[0-9]{5}$/.test(value)
            ? [true]
            : [false, "Please enter a valid zip code"]
        }
        required
        css={{ gridColumn: "span 2", [theme.mobile]: { gridColumn: "1" } }}
      />

      <EmailInput
        name="email"
        label="Email Address"
        autoComplete="email"
        required
        css={{ gridColumn: "span 3", [theme.mobile]: { gridColumn: "1" } }}
      />

      <TextInput
        name="phone"
        label="Phone Number"
        autoComplete="tel-national"
        required
        css={{ gridColumn: "span 3", [theme.mobile]: { gridColumn: "1" } }}
      />

      <TextInput
        name="entity"
        label="If entity, list form of entity and state of formation, if known"
        autoComplete="off"
        css={{ gridColumn: "span 6", [theme.mobile]: { gridColumn: "1" } }}
      />

      <TextInput
        name="attorney_involved"
        label="Attorney Involved"
        autoComplete="off"
        css={{ gridColumn: "span 6", [theme.mobile]: { gridColumn: "1" } }}
      />

      <CheckboxInput
        name="logo_usage"
        checkedValue="Agreed"
        uncheckedValue="Not Agreed"
        label={logoAgreementCopy}
        css={{ gridColumn: "span 6", [theme.mobile]: { gridColumn: "1" } }}
      />

      <div
        css={{
          textAlign: "center",
          marginTop: "2em",
          gridColumn: "1 / -1",
        }}
      >
        <Button css={{ marginRight: 0 }}>Next</Button>
      </div>
    </div>
  </FormPage>
)

export default ClientInformation

ClientInformation.propTypes = {
  logoAgreementCopy: PropTypes.string.isRequired,
}
