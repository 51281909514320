import React from "react"
import PropTypes from "prop-types"
import { Global } from "@emotion/core"
import { Helmet } from "react-helmet"
import theme, { globalStyles } from "../styles/theme"
import { builder } from "components/Image"
import Metadata from "components/Metadata"
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({
  background,
  contiguousBackground = false,
  metadata = {},
  children,
}) => (
  <>
    <Metadata {...metadata} />
    <Helmet>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cloud.typography.com/6779514/6953592/css/fonts.css"
      />
    </Helmet>
    <Global styles={globalStyles} />
    <Favicons />

    <div
      css={
        contiguousBackground && {
          [theme.mobile]: {
            backgroundColor: `${theme.n20}80`,
            backgroundImage:
              background &&
              `url(${builder
                .image(background)
                .width(700)
                .height(1400)
                .fit("crop")
                .url()})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          },
        }
      }
    >
      <Header layoutBackground={contiguousBackground ? null : background} />
      <main
        css={{
          backgroundSize: "cover",
          backgroundPosition: "center top",
          padding: "90px 100px 200px",
          backgroundImage: background && bgImage(background, 1440),
          [theme.tablet]: {
            padding: "60px 100px 140px",
            backgroundImage: background && bgImage(background, 1024),
          },
          [theme.mobile]: {
            padding: 0,
            paddingBottom: contiguousBackground ? 60 : 140,
            paddingLeft: contiguousBackground && 30,
            backgroundImage: contiguousBackground
              ? "none"
              : background &&
                `url(${builder
                  .image(background)
                  .width(700)
                  .height(400)
                  .fit("crop")
                  .focalPoint(0.5, 1)
                  .url()})`,
            backgroundPosition: "center bottom -1px",
            backgroundSize: "100% auto",
          },
        }}
      >
        {children}
      </main>
    </div>
    <Footer />
  </>
)

export default Layout

Layout.propTypes = {
  background: PropTypes.shape({
    asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  contiguousBackground: PropTypes.bool,
  metadata: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
}

const bgImage = (background, width) =>
  `url(${builder
    .image(background)
    .width(width)
    .url()})`

const Favicons = () => (
  <Helmet>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/manifest.json" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
    <meta name="theme-color" content="#ffffff"></meta>
  </Helmet>
)
