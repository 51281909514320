import React from "react"
import theme from "styles/theme"

const Button = props => (
  <button
    css={{
      appearance: "none",
      background: theme.teal,
      padding: "0.8em 2em",
      color: theme.n100,
      textTransform: "uppercase",
      fontSize: 14,
      fontWeight: 500,
      cursor: "pointer",
      border: 0,
      fontFamily: "inherit",
      transition: "0.2s",
      ":hover": { background: theme.n20 },
      "& + &": { marginLeft: 40 },
    }}
    {...props}
  />
)

export default Button
