import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import theme from "styles/theme"
import Layout from "components/Layout"
import ContentContainer from "components/ContentContainer"
import Image from "components/Image"
import BlockContent, { passNodeToPropsTo } from "components/BlockContent"
import VCardLink from "components/VCardLink"
import TeamMemberGrid from "components/TeamMemberGrid"
import linkedIn from "images/linkedin-icon.png"
import twitter from "images/twitter-icon.png"
import AutoLink from "components/AutoLink"

import Subcontent from "components/Subcontent"

const TeamMember = ({ data: { page, teamMember } }) => (
  <Layout
    background={page.background}
    metadata={{
      title: `${teamMember.firstName} ${teamMember.lastName}, ${teamMember.position} — ${page.organizationName}`,
    }}
  >
    <ContentContainer
      label={`Our Team / ${teamMember.firstName} ${teamMember.lastName}`}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginTop: 32,
          marginBottom: 30,
          "@media (max-width: 850px)": {
            display: "grid",
            gridTemplateColumns: "1fr",
            marginRight: 0,
          },
        }}
      >
        <Image
          {...teamMember.headshot}
          alt=""
          width={175}
          css={{
            display: "block",
            width: 175,
            "@media (max-width: 850px)": {
              marginBottom: 10,
            },
          }}
        />
        <div
          css={{
            flex: 1,
            paddingLeft: 30,
            "@media (max-width: 850px)": {
              paddingLeft: 0,
            },
          }}
        >
          <div
            css={{
              ...theme.h1,
              fontWeight: 700,
              margin: "0 -45px 15px 0",
              [theme.tablet]: {
                marginRight: 0,
              },
            }}
          >
            {teamMember.firstName} {teamMember.lastName}
          </div>
          <div
            css={{
              color: theme.red,
              fontSize: "0.625rem",
              fontWeight: 500,
              letterSpacing: "0.1071428571em",
            }}
          >
            <div css={theme.smallcaps(15)}>{teamMember.position}</div>
            <div
              css={{
                fontSize: 16,
                fontWeight: 400,
                color: theme.n20,
                margin: "30px 0 0",
                lineHeight: 1.7,
              }}
            >
              <div css={{ textTransform: "lowercase" }}>
                {teamMember.emailAddress}
              </div>
              <div>{teamMember.phoneNumber}</div>
              <div>
                <VCardLink {...teamMember} css={{ color: theme.red }}>
                  vCard
                </VCardLink>
              </div>
              <div css={{ marginTop: 12 }}>
                <AutoLink to={teamMember.linkedin} target="_blank">
                  <img
                    src={linkedIn}
                    alt="LinkedIn"
                    css={{
                      width: 30,
                    }}
                  />
                </AutoLink>
                {teamMember.twitter && (
                  <AutoLink to={teamMember.twitter} target="_blank">
                    <img
                      src={twitter}
                      alt="Twitter"
                      css={{
                        width: 30,
                        marginLeft: 12,
                      }}
                    />
                  </AutoLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <BlockContent
        css={{
          margin: 0,
        }}
        serializers={{
          types: {
            subcontent: passNodeToPropsTo(Subcontent),
          },
        }}
      >
        {teamMember.bio}
      </BlockContent>

      <TeamMemberGrid title="Team" css={{ marginTop: "4rem" }} />
    </ContentContainer>
  </Layout>
)

export default TeamMember

TeamMember.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      organizationName: PropTypes.string.isRequired,
      background: PropTypes.shape({
        asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
      }).isRequired,
    }).isRequired,
    teamMember: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
      emailAddress: PropTypes.string.isRequired,
      bio: PropTypes.array.isRequired,
      linkedin: PropTypes.url,
      twitter: PropTypes.url,
      headshot: PropTypes.shape({
        asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query TeamMemberQuery($slug: String!) {
    teamMember: sanityTeamMember(slug: { current: { eq: $slug } }) {
      firstName
      lastName
      position
      bio: _rawBiography
      emailAddress
      phoneNumber
      linkedin
      twitter
      officeAddress {
        address {
          street
          street2
          city
          state
          zipcode
        }
      }
      headshot {
        ...Image
      }
    }

    page: sanityTeamSettings {
      organizationName
      background: teamMemberBackground {
        ...Image
      }
    }
  }
`
