import { css } from "@emotion/core"
import wolpePegasusRegularWoff from "./wolpe-pegasus/wolpe-pegasus-regular.woff"
import wolpePegasusRegularWoff2 from "./wolpe-pegasus/wolpe-pegasus-regular.woff2"
import wolpePegasusBoldWoff from "./wolpe-pegasus/wolpe-pegasus-bold.woff"
import wolpePegasusBoldWoff2 from "./wolpe-pegasus/wolpe-pegasus-bold.woff2"
import wolpePegasusItalicWoff from "./wolpe-pegasus/wolpe-pegasus-italic.woff"
import wolpePegasusItalicWoff2 from "./wolpe-pegasus/wolpe-pegasus-italic.woff2"
import wolpePegasusBoldItalicWoff from "./wolpe-pegasus/wolpe-pegasus-bold-italic.woff"
import wolpePegasusBoldItalicWoff2 from "./wolpe-pegasus/wolpe-pegasus-bold-italic.woff2"

export default css`
  @font-face {
    font-family: "Wolpe Pegasus";
    src: url("${wolpePegasusRegularWoff}") format("woff"),
        url("${wolpePegasusRegularWoff2}") format("woff2");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "Wolpe Pegasus";
    src: url("${wolpePegasusBoldWoff}") format("woff"),
        url("${wolpePegasusBoldWoff2}") format("woff2");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Wolpe Pegasus";
    src: url("${wolpePegasusItalicWoff}") format("woff"),
        url("${wolpePegasusItalicWoff2}") format("woff2");
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: "Wolpe Pegasus";
    src: url("${wolpePegasusBoldItalicWoff}") format("woff"),
        url("${wolpePegasusBoldItalicWoff2}") format("woff2");
    font-weight: 700;
    font-style: italic;
  }
`
