import React from "react"
import PropTypes from "prop-types"
import theme from "../styles/theme"

const ContentContainer = ({ label, ...rest }) => (
  <div
    css={{
      maxWidth: 820,
      background: theme.white,
      padding: `80px ${theme.contentRightPadding} 80px ${theme.contentLeftPadding}`,
      position: "relative",
      marginLeft: "auto",
      marginRight: "auto",
      ":before": {
        content: "''",
        width: 40,
        height: 40,
        background: theme.red,
        position: "absolute",
        top: 0,
        left: 0,
      },

      [theme.desktop]: label && {
        ":after": {
          ...theme.smallcaps(14),
          content: `"${label}"`,
          color: theme.red,
          position: "absolute",
          top: 40 - 14,
          left: 40 + 17,
        },
      },

      [theme.tablet]: {
        // paddingLeft: theme.contentLeftPadding,
        // paddingRight: theme.contentRightPadding,

        ":before": {
          width: 30,
          height: 30,
        },
      },

      [theme.mobile]: {
        paddingTop: 40,
        paddingBottom: 60,

        ":before": {
          width: 20,
          height: 20,
          left: 18,
        },
      },
    }}
    {...rest}
  />
)

export default ContentContainer

ContentContainer.propTypes = {
  label: PropTypes.string,
}
