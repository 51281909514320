import React from "react"
import PropTypes from "prop-types"
import SanityBlockContent from "@sanity/block-content-to-react"
import deepMerge from "deepmerge"

const BlockContent = ({
  children,
  serializers = {},
  blockOverrides = {},
  ...props
}) => {
  return (
    <SanityBlockContent
      blocks={children}
      serializers={deepMerge.all([
        blockSerializer(blockOverrides),
        standardSerializers,
        serializers,
      ])}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.SANITY_DATASET}
      // Ensure that className prop has a DOM element to apply to
      renderContainerOnSingleChild={true}
      css={{
        a: {
          color: "inherit",
          textDecoration: "underline",
        },
      }}
      {...props}
    />
  )
}

export default BlockContent

BlockContent.propTypes = {
  children: PropTypes.array.isRequired,
  serializers: PropTypes.object,
  blockOverrides: PropTypes.object,
  imageOptions: PropTypes.object,
}

export const passNodeToPropsTo = component => ({ node: propsForComponent }) =>
  React.createElement(component, propsForComponent)

const blockSerializer = blockOverrides => ({
  types: {
    block: props =>
      blockOverrides.hasOwnProperty(props.node.style)
        ? blockOverrides[props.node.style]({ children: props.children })
        : SanityBlockContent.defaultSerializers.types.block(props),
  },
})

const standardSerializers = {}
