import React from "react"
import PropTypes from "prop-types"

/**
 * This renders a hidden form that communicates the necessary
 * information to Netlify for them to build a form handler for
 * a real form on the site that does not otherwise show up in
 * the static HTML.
 *
 * @param {object} props
 * @param {string} props.name The form name. This will be used in the Netlify interface and needs to match the `form-name` parameter included in form submissions.
 * @param {Array<string>} props.fields Array of field names. Netlify uses this to determine which input parameters to accept and display in the Forms UI.
 */
const NetlifyFormPlaceholder = ({ name, fields }) => (
  <form
    data-netlify="true"
    name={name}
    netlify-honeypot="bot-field"
    method="POST"
    hidden
  >
    {fields.map(name => (
      <input type="hidden" name={name} key={name} />
    ))}
  </form>
)

export default NetlifyFormPlaceholder

NetlifyFormPlaceholder.propTypes = {
  name: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
}
