import React, { useState } from "react"
import PropTypes from "prop-types"
import { useFormField } from "components/form/useFormField"
import ErrorMessage from "components/form/ErrorMessage"

const CheckboxInput = ({
  name,
  label,
  checkedValue = true,
  uncheckedValue = false,
  validator,
  required,
  defaultChecked = false,
  className,
  ...props
}) => {
  const { errorMessage, onChange } = useFormField({
    name,
    validator,
    required,
    defaultValue: defaultChecked ? checkedValue : uncheckedValue,
  })

  const [checked, setChecked] = useState(defaultChecked)

  const handleChange = () => {
    const newCheckedState = !checked
    setChecked(newCheckedState)
    onChange({
      target: { name, value: newCheckedState ? checkedValue : uncheckedValue },
    })
  }

  return (
    <div className={className}>
      <label
        css={{
          display: "flex",
          cursor: "pointer",
          fontSize: 14,
          marginTop: 16,
          marginBottom: 8,
        }}
        {...props}
      >
        <input
          name={name}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          css={{ marginTop: "0.125em", marginRight: "0.7em", flexShrink: 0 }}
        />
        <div>{label}</div>
      </label>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  )
}

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checkedValue: PropTypes.any,
  uncheckedValue: PropTypes.any,
  hint: PropTypes.string,
  validator: PropTypes.func,
  required: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  className: PropTypes.string,
}

export default CheckboxInput
