import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import ContentContainer from "components/ContentContainer"
import ClientIntakeForm from "components/ClientIntakeForm"
import NetlifyFormPlaceholder from "components/NetlifyFormPlaceholder"

const NewClientPage = ({
  data: {
    settings: { background, ...formSettings },
  },
}) => (
  <Layout background={background}>
    <ContentContainer css={{ paddingRight: 75, paddingLeft: 75 }}>
      <ClientIntakeForm {...formSettings} />
      <NetlifyFormPlaceholder
        name="client-intake-form"
        fields={[
          "client_name",
          "contact_name",
          "contact_title",
          "address_1",
          "address_2",
          "city",
          "state",
          "zip_code",
          "email",
          "phone",
          "entity",
          "attorney_involved",
          "logo_usage",
          "shirt_sizes",
          "artwork",
          "contact_name_shipping",
          "address_1_shipping",
          "address_2_shipping",
          "city_shipping",
          "state_shipping",
          "zip_code_shipping",
          "email_shipping",
          "phone_shipping",
        ]}
      />
    </ContentContainer>
  </Layout>
)

export default NewClientPage

NewClientPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.shape({
      title: PropTypes.string.isRequired,
      logoAgreementCopy: PropTypes.string.isRequired,
      successPageTitle: PropTypes.string.isRequired,
      successPageCopy: PropTypes.array.isRequired,
      background: PropTypes.shape({
        asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  {
    settings: sanityClientIntakeFormSettings {
      title
      logoAgreementCopy
      successPageTitle
      successPageCopy: _rawSuccessPageCopy
      background: image {
        ...Image
      }
    }
  }
`
