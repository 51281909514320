import React from "react"
import theme from "styles/theme"

const FancyList = props => (
  <ul
    css={{
      listStyle: "none",
      li: {
        position: "relative",
        marginBottom: 24,
        ":before": {
          content: "''",
          width: 12,
          height: 12,
          position: "absolute",
          background: theme.red,
          left: -12 - 24,
          top: 4,
        },
        [theme.mobile]: {
          marginBottom: 16,
        },
      },
    }}
    {...props}
  />
)

export default FancyList
