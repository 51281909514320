import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import theme from "styles/theme"
import Layout from "components/Layout"
import ContentContainer from "components/ContentContainer"
import FancyList from "components/FancyList"
import AutoLink from "components/AutoLink"
import PortableText from "react-portable-text"

const DirectoryPage = ({
  data: {
    page: { background, title, intro, sidebarLinks, footerLinks, metadata },
  },
}) => (
  <Layout background={background} metadata={metadata} contiguousBackground>
    <ContentContainer>
      <h1
        css={{
          ...theme.h1,
          marginBottom: 18,
          marginRight: "calc(var(--contentRightPadding) * -1 + 30px)",
        }}
      >
        {title}
      </h1>

      {(intro || sidebarLinks?.length > 0) && (
        <div
          css={{
            display: "grid",
            justifyContent: "space-between",
            gridTemplateColumns:
              intro && sidebarLinks?.length > 0 ? "1fr 250px" : "1fr",
            alignItems: "flex-start",
            gap: 50,
            marginRight: -100,
            marginBottom: 30,
            [theme.tablet]: {
              marginRight: -50,
            },
            "@media (max-width: 850px)": {
              gridTemplateColumns: "1fr",
              marginRight: 0,
            },
            [theme.mobile]: {
              gap: 30,
            },
          }}
        >
          <div css={{ fontSize: 18, lineHeight: 1.33 }}>
            {intro && (
              <PortableText
                content={intro}
                css={{
                  a: {
                    color: "inherit",
                    textDecoration: "underline",
                  },
                  "p:first-of-type": {
                    marginTop: 0,
                  },
                  "p:last-of-type": {
                    marginBottom: 0,
                  },
                }}
              />
            )}
          </div>

          {sidebarLinks?.length > 0 && (
            <FancyList css={{ margin: 0 }}>
              {sidebarLinks.map(({ _key, title, url }) => (
                <li key={_key}>
                  <a
                    href={url}
                    css={{
                      ...theme.smallcaps(12, 700),
                      color: theme.teal,
                    }}
                  >
                    {title}
                  </a>
                </li>
              ))}
            </FancyList>
          )}
        </div>
      )}
      <ContentFooterLinks links={footerLinks} />
    </ContentContainer>
  </Layout>
)

export default DirectoryPage

DirectoryPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      background: PropTypes.shape({
        asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
      }).isRequired,
      title: PropTypes.string.isRequired,
      intro: PropTypes.arrayOf(PropTypes.object),
      sidebarLinks: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }).isRequired
      ),
      footerLinks: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }).isRequired
      ),
      metadata: Layout.propTypes.metadata,
    }).isRequired,
  }).isRequired,
}

const ContentFooterLinks = ({ links, className }) => (
  <div
    css={{
      display: "flex",
      justifyContent: "space-around",
      padding: 30,
      maxWidth: "none",
      marginLeft: -100,
      marginRight: -180,
      [theme.tablet]: {
        marginLeft: -70,
        marginRight: -80,
        marginBottom: -48,
      },
      [theme.mobile]: {
        display: "none",
      },
    }}
    className={className}
  >
    {links.map(({ title, url }) => (
      <AutoLink
        key={url}
        href={url}
        css={{
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.2,
          letterSpacing: 2,
          textTransform: "uppercase",
          color: theme.red,
          textDecoration: "none",
        }}
      >
        / {title}
      </AutoLink>
    ))}
  </div>
)

ContentFooterLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  className: PropTypes.string,
}

export const query = graphql`
  query DirectoryPageQuery($slug: String!) {
    page: sanityDirectoryPage(slug: { current: { eq: $slug } }) {
      title
      intro: _rawIntro
      sidebarLinks {
        _key
        ...Link
      }
      footerLinks {
        ...Link
      }
      background {
        ...Image
      }
      metadata {
        ...PageMetadata
      }
    }
  }
`
