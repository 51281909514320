import React from "react"
import PropTypes from "prop-types"
import theme from "styles/theme"

const FormPage = ({ title, intro, children }) => (
  <div>
    <h2 css={theme.h2}>{title}</h2>
    {intro && <p>{intro}</p>}

    {children}
  </div>
)

export default FormPage

FormPage.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string,
  children: PropTypes.node.isRequired,
}
