// Convert t-shirt sizes input to and from string values for
// submitting with the form.

export const serializeValue = value =>
  Object.entries(value)
    .map(([key, value]) => `${key.toUpperCase()}:${value}`)
    .join(", ")

export const deserializeValue = value => ({
  xs: 0,
  s: 0,
  m: 0,
  l: 0,
  xl: 0,
  ...Object.fromEntries(
    (value || "")
      .split(", ")
      .filter(Boolean)
      .map(pair => {
        const [k, v] = pair.split(":")
        return [k.toLowerCase(), parseInt(v, 10)]
      })
  ),
})
