import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import BlockContent, { passNodeToPropsTo } from "../components/BlockContent"
import theme from "styles/theme"
import Layout from "components/Layout"
import ContentContainer from "components/ContentContainer"

import TeamMemberGrid from "components/TeamMemberGrid"
import Subcontent from "components/Subcontent"
import ClientGrid from "components/ClientGrid"

const Page = ({
  data: {
    page: { background, pageLabel, title, content, metadata },
  },
}) => (
  <Layout background={background} metadata={metadata}>
    <ContentContainer label={pageLabel}>
      <h1 css={theme.h1}>{title}</h1>
      <BlockContent
        /* eslint-disable react/display-name */
        blockOverrides={{
          h3: props => <h2 css={theme.h2} {...props} />,
        }}
        /* eslint-enable react/display-name */
        serializers={{
          types: {
            teamMemberGrid: passNodeToPropsTo(TeamMemberGrid),
            subcontent: passNodeToPropsTo(Subcontent),
            clientGrid: passNodeToPropsTo(ClientGrid),
          },
        }}
      >
        {content}
      </BlockContent>
    </ContentContainer>
  </Layout>
)

export default Page

Page.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      background: PropTypes.shape({
        asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
      }).isRequired,
      pageLabel: PropTypes.string,
      title: PropTypes.string.isRequired,
      content: PropTypes.array.isRequired,
      metadata: Layout.propTypes.metadata,
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query PageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title
      pageLabel
      content: _rawPageContentBlock
      background {
        ...Image
      }
      metadata {
        ...PageMetadata
      }
    }
  }
`
