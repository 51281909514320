import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import theme from "../styles/theme"
import AutoLink from "components/AutoLink"
import { builder } from "components/Image"
import Dropdown from "components/Dropdown"
import { ReactComponent as Logo } from "images/logo.svg"

const Header = ({ layoutBackground }) => {
  const {
    settings: { links },
  } = useStaticQuery(graphql`
    {
      settings: sanityHeaderSettings {
        links {
          ... on SanityLink {
            _type
            _key
            ...Link
          }
          ... on SanityDropdownLink {
            _type
            _key
            text
            dropdownLinks {
              _key
              ...Link
            }
          }
        }
      }
    }
  `)

  return (
    <header
      css={{
        display: "grid",
        gridTemplateColumns: "300px 1fr",
        gridTemplateRows: "1fr 1fr",
        justifyContent: "space-between",
        padding: "50px 72px 83px 63px",
        background: theme.n100,

        [theme.tablet]: {
          paddingBottom: 30,
        },

        [theme.mobile]: {
          display: "block",
          padding: 0,
          background: "none",
        },
      }}
    >
      <div
        css={{
          [theme.mobile]: {
            background: theme.n20,
            padding: "17px 23px",
          },
        }}
      >
        <AutoLink to="/">
          <Logo
            css={{
              display: "block",
              color: theme.teal,
              width: 294,
              [theme.mobile]: { width: 200, color: theme.n100 },
            }}
          />
        </AutoLink>
      </div>

      <nav
        css={{
          gridRow: 2,
          gridColumn: "1 / 3",
          textAlign: "right",
          [theme.tablet]: {
            marginTop: 20,
          },

          [theme.mobile]: {
            marginTop: 0,
            paddingTop: 20,
            paddingBottom: 20,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            backgroundImage:
              layoutBackground &&
              `url(${builder
                .image(layoutBackground)
                .width(700)
                .height(400)
                .fit("crop")
                .focalPoint(0.5, 0)
                .url()})`,
            backgroundSize: "cover",
            backgroundPosition: "center top",
          },
        }}
      >
        <div
          css={{
            [theme.mobile]: {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {links.map(({ _type, _key, url, title, text, dropdownLinks }) =>
            _type === "link" ? (
              <AutoLink
                key={_key}
                to={url}
                css={{
                  ...theme.smallcaps(14, 400),
                  color: theme.teal,
                  marginLeft: 40,
                  [theme.tablet]: theme.smallcaps(12),
                  [theme.mobile]: {
                    display: "block",
                    background: theme.teal,
                    color: theme.n100,
                    padding: "10px 48px 10px 16px",
                    marginBottom: 7,
                    marginLeft: 0,
                    textAlign: "left",
                  },
                }}
              >
                {title}
              </AutoLink>
            ) : (
              <Dropdown key={_key} label={text} links={dropdownLinks} />
            )
          )}
        </div>
      </nav>
    </header>
  )
}

export default Header

Header.propTypes = {
  layoutBackground: PropTypes.shape({
    asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  }),
}
