/**
 * Sorts an array of strings in a consistent, case-insensitive way across all common
 * browsers.
 * @param {Array} inputArray Array to be sorted; will not be mutated.
 * @param {string} property If the array is of objects, a simple property to sort on.
 * @returns {Array} Sorted array
 */
const safeSort = (inputArray, property = null) =>
  [...inputArray].sort((a, b) =>
    (property ? a[property] : a).localeCompare(
      property ? b[property] : b,
      "en",
      { sensitivity: "base" }
    )
  )

export default safeSort
