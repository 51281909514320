import React from "react"
import PropTypes from "prop-types"
import { jsx } from "@emotion/core"
import AutoLink from "components/AutoLink"

const OptionalLink = ({
  to,
  href,
  target,
  rel,
  fallback = "span",
  linkStyles,
  fallbackStyles,
  ...rest
}) => {
  if (to || href) {
    return (
      <AutoLink
        href={to || href}
        target={target}
        rel={rel}
        css={linkStyles}
        {...rest}
      />
    )
  } else {
    return jsx(fallback, { css: fallbackStyles, ...rest })
  }
}

export default OptionalLink

OptionalLink.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  fallback: PropTypes.node,
}
