import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ValidatedForm from "components/form/ValidatedForm"
import BlockContent from "components/BlockContent"
import ShirtSignup from "components/form/ShirtSignup"
import ClientInformation from "components/form/ClientInformation"
import Confirmation from "components/form/Confirmation"
import Button from "components/Button"
import theme from "styles/theme"

const ClientIntakeForm = ({
  title,
  logoAgreementCopy,
  successPageTitle,
  successPageCopy,
}) => {
  const [state, setState] = useState({
    clientInfo: null,
    shirtInfo: null,
    page: 1,
    error: null,
  })

  useEffect(() => {
    const currentUrl = new URL(window.location.href)

    if (currentUrl.searchParams.get("page") !== state.page) {
      currentUrl.searchParams.set("page", state.page)
      window.history.pushState({ page: state.page }, "", currentUrl.toString())
    }
  }, [state.page])

  useEffect(() => {
    const popHandler = ({ state: popState }) => {
      setState(prev => ({ ...prev, ...popState }))
    }

    window.addEventListener("popstate", popHandler)

    return () => {
      window.removeEventListener("popstate", popHandler)
    }
  }, [])

  return (
    <div>
      <h1 css={theme.h1}>{title}</h1>

      {state.page === 1 ? (
        <ValidatedForm
          key="page-1"
          initialFields={state.clientInfo}
          onSubmit={({ fields }) => {
            setState(prev => ({
              ...prev,
              clientInfo: fields,
              page: 2,
            }))
          }}
        >
          {state.error && <div css={{ color: "red" }}>{state.error}</div>}
          <ClientInformation logoAgreementCopy={logoAgreementCopy} />
        </ValidatedForm>
      ) : state.page === 2 ? (
        <ValidatedForm
          key="page-2"
          initialFields={state.shirtInfo}
          onSubmit={({ fields }) => {
            const { shipToBilling, ...shirtInfo } = fields

            setState(prev => {
              if (shipToBilling.value) {
                shirtInfo.address_1_shipping = prev.clientInfo.address_1
                shirtInfo.address_2_shipping = prev.clientInfo.address_2
                shirtInfo.city_shipping = prev.clientInfo.city
                shirtInfo.state_shipping = prev.clientInfo.state
                shirtInfo.zip_code_shipping = prev.clientInfo.zip_code
              }

              return {
                ...prev,
                shirtInfo,
                page: 3,
              }
            })
          }}
        >
          <ShirtSignup />
          {state.error && <div css={{ color: "red" }}>{state.error}</div>}

          <div
            css={{
              textAlign: "center",
              marginTop: "2em",
              gridColumn: "1 / -1",
            }}
          >
            <Button
              onClick={() => {
                setState(prev => ({ ...prev, page: 3, shirtInfo: null }))
              }}
            >
              Skip
            </Button>
            <Button>Next</Button>
          </div>
        </ValidatedForm>
      ) : state.page === 3 ? (
        <>
          <Confirmation state={state} />
          <div
            css={{
              textAlign: "center",
              marginTop: "2em",
              gridColumn: "1 / -1",
            }}
          >
            <Button
              onClick={async () => {
                await handleSubmit({
                  ...state.clientInfo,
                  ...(state.shirtInfo || {}),
                })
                setState(prev => ({
                  ...prev,
                  page: 4,
                }))
              }}
            >
              Submit
            </Button>
          </div>
        </>
      ) : (
        <div key="page-4" css={{ textAlign: "center", margin: "100px 0" }}>
          <h1 css={{ ...theme.h1, color: theme.red }}>{successPageTitle}</h1>
          <BlockContent>{successPageCopy}</BlockContent>
        </div>
      )}
    </div>
  )
}

export default ClientIntakeForm

ClientIntakeForm.propTypes = {
  title: PropTypes.string.isRequired,
  logoAgreementCopy: PropTypes.string.isRequired,
  successPageTitle: PropTypes.string.isRequired,
  successPageCopy: PropTypes.array.isRequired,
}

const handleSubmit = fields => {
  const formData = new global.FormData()
  formData.append("form-name", "client-intake-form")

  Object.entries(fields).forEach(([fieldName, { value }]) => {
    if (typeof value === "undefined") return

    formData.append(fieldName, value)
  })

  return window.fetch("/", {
    method: "POST",
    body: formData,
  })
}
