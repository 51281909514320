import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import safeSort from "lib/safeSort"
import Image from "components/Image"
import OptionalLink from "components/OptionalLink"
import theme from "styles/theme"

const ClientGrid = ({ title, className }) => {
  const {
    data: { clients },
  } = useStaticQuery(graphql`
    {
      data: allSanityClient(sort: { fields: clientName }) {
        clients: nodes {
          _id
          clientName
          clientWebsiteLink
          clientLogo {
            ...Image
          }
        }
      }
    }
  `)
  const sortedClients = safeSort(clients, "clientName")

  return (
    <div css={{ marginTop: 64 }} className={className}>
      <h2 css={theme.h2}>{title}</h2>

      <div
        css={{
          display: "grid",
          gap: "20px 40px",
          gridTemplateColumns: "repeat(4, 1fr)",
          marginTop: 40,
          marginLeft: -30,
          marginRight: -110,
          [theme.tablet]: {
            gridTemplateColumns: "repeat(auto-fill,minmax(140px,max-content))",
            marginLeft: 0,
            marginRight: 0,
          },
          [theme.mobile]: {
            gridTemplateColumns: "repeat(auto-fill,minmax(110px,max-content))",
            gridGap: "15px 28px",
            marginLeft: 0,
            marginRight: 0,
          },
        }}
      >
        {sortedClients.map(
          ({ _id, clientName, clientLogo, clientWebsiteLink }) => (
            <ClientLogo
              key={_id}
              name={clientName}
              logo={clientLogo}
              url={clientWebsiteLink}
            />
          )
        )}
      </div>
    </div>
  )
}

export default ClientGrid

ClientGrid.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

const ClientLogo = ({ name, logo, url }) => (
  <OptionalLink to={url} target="_blank">
    <Image
      {...logo}
      alt={name}
      width={175}
      css={{
        display: "block",
      }}
    />
  </OptionalLink>
)

ClientLogo.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    asset: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
  url: PropTypes.string,
}
