import React from "react"
import theme from "../styles/theme"
import { useStaticQuery, graphql } from "gatsby"
import AutoLink from "components/AutoLink"
import Image from "components/Image"

const Footer = () => {
  const {
    footer: { name, logo, copyright, disclaimer, links, location },
  } = useStaticQuery(graphql`
    {
      footer: sanityFooterSettings {
        name
        logo {
          ...Image
        }
        copyright
        disclaimer
        location
        links {
          ...Link
        }
      }
    }
  `)

  return (
    <div
      css={{
        background: theme.n20,
        position: "relative",
        color: theme.white,
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: 20,
        padding: "120px 60px 50px",

        [theme.tablet]: {
          padding: "112px 56px 46px",
        },

        [theme.mobile]: {
          display: "block",
          padding: "60px 40px 50px",
        },

        [[":before", ":after"]]: {
          content: "''",
          height: 40,
          width: "33%",
          position: "absolute",
          top: 0,

          [theme.tablet]: {
            height: 23,
          },

          [theme.mobile]: {
            height: 14,
            width: "25%",
          },
        },

        ":before": {
          left: 0,
          background: theme.red,
        },

        ":after": {
          right: 0,
          background: "#264A5C",
        },
      }}
    >
      <nav
        css={{
          order: 3,
          gridColumn: "1 / 3",
          gridRow: 4,

          a: {
            ...theme.smallcaps(12),
            color: theme.teal,
            textDecoration: "none",
            fontSize: 12,
            marginRight: 18,

            ":last-child": {
              marginRight: 0,
            },
            [theme.tablet]: {
              letterSpacing: 1.4,
            },

            [theme.mobile]: {
              display: "block",
              color: theme.n100,
              margin: "0 0 23px",
              letterSpacing: 1.4,
            },
          },
        }}
      >
        {links.map(({ title, url }) => (
          <AutoLink key={url} to={url}>
            {title}
          </AutoLink>
        ))}
      </nav>

      <div
        css={{
          order: 1,
          fontSize: 11,
          fontFamily: theme.displayFontFamily,
          lineHeight: 2,

          [theme.mobile]: {
            marginTop: 32,
          },
        }}
      >
        <span
          css={{
            ...theme.smallcaps(11, 700),
            color: theme.red,
            display: "block",
            letterSpacing: 0.136,
          }}
        >
          {name}
        </span>
        {location}
      </div>

      <div
        css={{
          order: 2,
          textAlign: "right",

          [theme.mobile]: {
            margin: "52px 0 12px",
            order: "unset",
            textAlign: "unset",
          },

          img: {
            width: 227,
            [theme.tablet]: { width: 170 },
            [theme.mobile]: { width: 227 },
          },
        }}
      >
        <Image {...logo} alt="" />
      </div>

      <div
        css={{
          fontFamily: theme.fontFamily,
          fontSize: 10,
          fontWeight: 300,
          letterSpacing: "0.025em",
          order: 3,
          gridColumn: "1 / 3",
          textAlign: "right",

          [theme.mobile]: {
            textAlign: "left",
          },
        }}
      >
        <div>{copyright}</div>
        <div>{disclaimer}</div>
      </div>
    </div>
  )
}

export default Footer
