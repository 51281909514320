import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useFormField } from "./useFormField"
import ErrorMessage from "components/form/ErrorMessage"
import theme from "styles/theme"

const validateRequired = (value, callback) =>
  value ? (callback ? callback(value) : [true]) : [false, "Required"]

const FileInput = ({
  name,
  label,
  type = "text",
  required,
  validator,
  transformer,
  className,
  ...rest
}) => {
  // Memoize this value to prevent infinite loops
  const combinedValidator = useMemo(
    () => (required ? value => validateRequired(value, validator) : validator),
    [required, validator]
  )

  const { errorMessage, value, ...eventListeners } = useFormField({
    name,
    validator: combinedValidator,
    required: false,
  })

  return (
    <div
      css={{
        position: "relative",
      }}
      className={className}
    >
      {label && (
        <label
          htmlFor={name}
          css={{
            position: "relative",
            fontSize: 13,
            textTransform: "uppercase",
            fontWeight: 500,
            display: "block",
            color: theme.teal,
            marginBottom: "0.25em",
            cursor: "pointer",
          }}
        >
          {required && (
            <abbr
              title="required"
              css={{
                color: theme.red,
                textDecoration: "none !important",
                position: "absolute",
                left: "-0.5em",
              }}
            >
              *
            </abbr>
          )}
          {label}
        </label>
      )}
      <input
        name={name}
        type="file"
        required={required}
        css={{
          display: "block",
          width: "100%",
          fontFamily: "inherit",
          padding: "0.5em 0.7em",
          border: `1px solid ${errorMessage ? theme.red : theme.n60}`,
          fontSize: 14,
          color: theme.teal,
          ":focus": {
            outline: 0,
            borderColor: theme.n20,
          },
        }}
        {...rest}
        onChange={e => {
          eventListeners.onChange({
            target: { name, value: e.target.files[0] },
          })
        }}
        onBlur={eventListeners.onBlur}
      />

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  )
}

export default FileInput

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  transformer: PropTypes.func,
  validator: PropTypes.func,
  className: PropTypes.string,
}
