import React from "react"
import PropTypes from "prop-types"
import FormPage from "components/form/FormPage"
import { deserializeValue } from "components/form/serializers"

const Confirmation = ({ state: { clientInfo, shirtInfo } }) => {
  clientInfo = valuesFrom(clientInfo)
  shirtInfo = shirtInfo && valuesFrom(shirtInfo)
  return (
    <FormPage title="Review Input">
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 56,
          maxWidth: 500,
          marginInline: "auto",
        }}
      >
        <div>
          <h4 css={{ marginBottom: 8 }}>Client Info</h4>
          <address css={{ fontStyle: "normal" }}>
            <div>{clientInfo.client_name}</div>
            <div>{clientInfo.address_1}</div>
            {clientInfo.address_2 && <div>{clientInfo.address_2}</div>}
            <div>
              {clientInfo.city}, {clientInfo.state} {clientInfo.zip_code}
            </div>
          </address>
        </div>

        <div>
          <h4 css={{ marginBottom: 8 }}>Contact Info</h4>
          <div>
            {clientInfo.contact_name}, {clientInfo.contact_title}
          </div>
          <div>{clientInfo.phone}</div>
          <div>{clientInfo.email}</div>
        </div>

        {shirtInfo ? (
          <>
            <div>
              <h4 css={{ marginBottom: 8 }}>T-Shirt Shipping Address</h4>
              <address css={{ fontStyle: "normal" }}>
                <div>{shirtInfo.contact_name_shipping}</div>
                <div>{shirtInfo.address_1_shipping}</div>
                {shirtInfo.address_2_shipping && (
                  <div>{shirtInfo.address_2_shipping}</div>
                )}
                <div>
                  {shirtInfo.city_shipping}, {shirtInfo.state_shipping}{" "}
                  {shirtInfo.zip_code_shipping}
                </div>
              </address>
            </div>

            <div>
              <h4 css={{ marginBottom: 8 }}>Requested T-Shirt Sizes</h4>

              <ul>
                {Object.entries(deserializeValue(shirtInfo.shirt_sizes))
                  .filter(([_, value]) => value > 0)
                  .map(([sizeName, value]) => (
                    <li key={sizeName}>
                      {value} {sizeName.toUpperCase()}
                    </li>
                  ))}
              </ul>
            </div>
          </>
        ) : (
          <div
            css={{ background: "#ebebeb", padding: 24, gridColumn: "1 / -1" }}
          >
            No t-shirts were requested.
          </div>
        )}
      </div>
    </FormPage>
  )
}

export default Confirmation

Confirmation.propTypes = {
  state: PropTypes.object.isRequired,
}

const valuesFrom = fields => {
  return Object.keys(fields).reduce((acc, key) => {
    acc[key] = fields[key].value
    return acc
  }, {})
}
